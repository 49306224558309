import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import MainNav from "components/functional/mainNav";

import Layout from "components/layout";

import { StaticImage } from "gatsby-plugin-image";

const metaData = {
  title:
    "Theatre Fans Refuse to Take a Bow: Audiences Return in Force | D E W Y N T E R S",
  description: "Theatre Fans Refuse to Take a Bow: Audiences Return in Force",
  image: "images/news/DW-2023-03-20-DWDigest2-webimage-970x542.jpg",
};

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }

  .app-img {
    margin-bottom: 1rem;
  }
`;
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
  .image {
    margin: 1rem 0;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
    @media ${media.md} {
      font-size: 33px;
    }
  }

  .body {
    margin-top: 1rem;
    .highlight {
      color: #09dbd8;
    }
    a {
      text-decoration: underline 2px;
      color: #09dbd8 !important;
      &:hover,
      &:active {
        transform: scale(1.5);
      }
    }
    .no-colour-change {
      color: #000 !important;
    }
    p {
      margin-bottom: 1rem;

      strong {
        font-weight: 900;
        font-family: var(--PoppinsBold);
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`;
const LatestNews = ({ data }) => {
  return (
    <Layout
      title={metaData.title}
      description={metaData.description}
      image={metaData.image}
    >
      <MainNav alt />
      <NewsBody>
        <div className="news">
          <SingleNewsStyles>
            <div className="header">
              <StaticImage
                src="../../images/news/DW-2023-03-20-DWDigest2-webimage-970x542.jpg"
                alt="Theatre Fans Refuse to Take a Bow: Audiences Return in Force | Dewynters"
                className="app-img"
                layout="fullWidth"
              />
              <h1>
              Theatre Fans Refuse to Take a Bow: Audiences Return in Force 
              </h1>
              <p><strong>By Alex Ollington </strong></p>
            </div>
            <div className="body">
            <p>After a long and challenging hiatus, Q1 saw some good news for theatre in the UK &ndash; theatregoers are returning to their beloved venues in increasing numbers. According to the latest data from YouGov in March 2023, <strong>one in five of their UK panellists had been to the theatre in the last three months</strong>, marking a significant milestone in the recovery of the industry.&nbsp;</p>
            <div className="image">
                <StaticImage layout="fullWidth" src="../../images/news/graph-101.png" alt="Theatre Fans Refuse to Take a Bow: Audiences Return in Force | Dewynters" />
              </div>
              <p>The fact that theatre attendance has returned to pre-pandemic levels for the first time since March 2020 is very much a cause for celebration and optimism. While the overall data tells us that attendance levels have returned to 2020 numbers, <strong>there are, however, notable differences between age groups, regions of the UK</strong>, <strong>and individual booking numbers.</strong></p>


              <div className="image">
                <StaticImage layout="fullWidth" src="../../images/news/graph-102.png" alt="Theatre Fans Refuse to Take a Bow: Audiences Return in Force | Dewynters" />
              </div>
              <p>Outside of London and Southern England, <strong>the rest of England has returned to pre-pandemic levels</strong>, with theatre attendance figures matching those seen in Q1 of 2020, before the pandemic hit. <strong>Scotland and Wales are still lagging behind</strong>, with attendance still below pre-pandemic levels, but an eventual recovery to those Q1 of 2020 numbers in Scotland and Wales is likely over the next few months.</p>              
              <div className="image">
                <StaticImage layout="fullWidth" src="../../images/news/graph-103.png" alt="Theatre Fans Refuse to Take a Bow: Audiences Return in Force | Dewynters" />
              </div>
              <p>It&rsquo;s perhaps no surprise that<strong> younger theatre audiences have declined from 18% in March 2020 to 16% in March 2023</strong>, indicating that the cost-of-living crisis may have affected them more than older age groups. On the other hand, <strong>theatre attendance among 50-64-year-olds has increased</strong> to 22% in Q1 of 2023, up from 20% in Q1 of 2020. Whilst this <strong>older age group had been slower to return,</strong> they have come back in force; as one of the age groups (generally) less affected by the rising cost of living, it seems they&rsquo;re still happy to spend their time and money at the theatre.</p>
<p>So how is theatre measuring up against the wider entertainment landscape? The <strong>cinema industry has struggled to attract audiences</strong>, with attendance levels in 2022 reaching only <a href='https://cineuropa.org/en/newsdetail/438675/' target='_blank'>67% of what was seen during the 2017-2019 period</a>. This trend has emerged due to the industry's shift towards prioritising smaller releases and in-home streaming services, making blockbuster films more affordable to viewers, and contributing to a decline in out-of-home cinema attendance. Better news for <strong>art gallery and exhibition attendance, </strong>which has also <a href='https://yougov.co.uk/topics/arts/trackers/how-often-brits-go-to-art-galleries-and-exhibitions' target='_blank'>returned to pre-pandemic levels</a>.</p>
<p>While theatre attendance numbers have returned to pre-pandemic levels, it does appear that <strong>bookers are going less often, which is impacting overall sales</strong>. Recent <a href='https://www.theaudienceagency.org/evidence/covid-19-cultural-participation-monitor/participation-and-attendance/change-in-sales-a-summary-from-audience-finder-data' target='_blank'>research from The Audience Agency</a> has revealed that, currently, individual bookings are broadly at 88% of pre-pandemic levels; yet at the same time, booker numbers are only down 3% on 2017-2019 levels. This means that although the <em>overall number</em> of tickets sold is down, the number of bookers has largely returned to prior numbers, albeit with a lower frequency of attendance.</p>
<p>Reassuring news is that despite the ongoing cost-of-living crisis, <strong>cutting visits to the theatre and live entertainment is at the bottom of the list for theatregoers </strong>(only 3% of YouGov respondents are considering cutting spending on large leisure events and this goes down to 1% of theatregoers). So, whilst audiences are excited and willing to return to the magic of theatre, they may do so less often&hellip;for now!</p>
<p>Despite the pandemic's unprecedented impact on the arts industry, the recovery of the theatre and art sectors serves as a testament to the enduring appeal and importance of live cultural experiences. The fact that theatregoer numbers have bounced back to pre-pandemic levels should be celebrated. The UK arts industry has weathered a challenging storm, but it has emerged stronger and more robust than ever.</p>
<p>The future of UK theatre is bright, and we can't wait to see what comes next.</p>


            </div>
            <p>
              <strong>
                <Link to="/latest-news/">{`< Back`}</Link>
              </strong>
            </p>
          </SingleNewsStyles>
        </div>
      </NewsBody>
    </Layout>
  );
};

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
};

export default LatestNews;
